/* body{
    text-align: center;
} */

/* CSS background */

@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
  }
  
  .background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #3E1E68;
    overflow: hidden;
  }
  
  .background span {
    width: 20vmin;
    height: 20vmin;
    border-radius: 20vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 45;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  
  .background span:nth-child(0) {
    color: #E45A84;
    top: 50%;
    left: 28%;
    animation-duration: 41s;
    animation-delay: -12s;
    transform-origin: 25vw 3vh;
    box-shadow: 40vmin 0 5.5509582725325926vmin currentColor;
  }
  .background span:nth-child(1) {
    color: #FFACAC;
    top: 69%;
    left: 14%;
    animation-duration: 31s;
    animation-delay: -48s;
    transform-origin: -23vw -11vh;
    box-shadow: -40vmin 0 5.961656893456132vmin currentColor;
  }
  .background span:nth-child(2) {
    color: #E45A84;
    top: 65%;
    left: 41%;
    animation-duration: 55s;
    animation-delay: -40s;
    transform-origin: 13vw 0vh;
    box-shadow: -40vmin 0 5.510652454444377vmin currentColor;
  }
  .background span:nth-child(3) {
    color: #583C87;
    top: 59%;
    left: 14%;
    animation-duration: 43s;
    animation-delay: -14s;
    transform-origin: 3vw 22vh;
    box-shadow: 40vmin 0 5.906923956588535vmin currentColor;
  }
  .background span:nth-child(4) {
    color: #583C87;
    top: 54%;
    left: 24%;
    animation-duration: 36s;
    animation-delay: -13s;
    transform-origin: -1vw -8vh;
    box-shadow: 40vmin 0 5.7959442021458525vmin currentColor;
  }
  .background span:nth-child(5) {
    color: #FFACAC;
    top: 21%;
    left: 63%;
    animation-duration: 44s;
    animation-delay: -44s;
    transform-origin: 1vw 23vh;
    box-shadow: -40vmin 0 5.061171945724339vmin currentColor;
  }
  .background span:nth-child(6) {
    color: #583C87;
    top: 12%;
    left: 87%;
    animation-duration: 39s;
    animation-delay: -11s;
    transform-origin: -16vw -11vh;
    box-shadow: -40vmin 0 5.65163276073673vmin currentColor;
  }
  .background span:nth-child(7) {
    color: #FFACAC;
    top: 86%;
    left: 77%;
    animation-duration: 34s;
    animation-delay: -36s;
    transform-origin: 15vw -19vh;
    box-shadow: -40vmin 0 5.6425229861137804vmin currentColor;
  }
  .background span:nth-child(8) {
    color: #FFACAC;
    top: 15%;
    left: 75%;
    animation-duration: 43s;
    animation-delay: -46s;
    transform-origin: 8vw -4vh;
    box-shadow: 40vmin 0 5.611049099833652vmin currentColor;
  }
  .background span:nth-child(9) {
    color: #583C87;
    top: 77%;
    left: 57%;
    animation-duration: 41s;
    animation-delay: -2s;
    transform-origin: -23vw 23vh;
    box-shadow: -40vmin 0 5.8430929337943205vmin currentColor;
  }
  .background span:nth-child(10) {
    color: #FFACAC;
    top: 59%;
    left: 83%;
    animation-duration: 10s;
    animation-delay: -14s;
    transform-origin: -20vw -22vh;
    box-shadow: 40vmin 0 5.630212037556413vmin currentColor;
  }
  .background span:nth-child(11) {
    color: #FFACAC;
    top: 96%;
    left: 73%;
    animation-duration: 54s;
    animation-delay: -10s;
    transform-origin: 9vw 25vh;
    box-shadow: 40vmin 0 5.116428887668621vmin currentColor;
  }
  .background span:nth-child(12) {
    color: #E45A84;
    top: 17%;
    left: 30%;
    animation-duration: 45s;
    animation-delay: -16s;
    transform-origin: 16vw 2vh;
    box-shadow: -40vmin 0 5.5110773761671386vmin currentColor;
  }
  .background span:nth-child(13) {
    color: #FFACAC;
    top: 17%;
    left: 67%;
    animation-duration: 32s;
    animation-delay: -41s;
    transform-origin: 23vw 4vh;
    box-shadow: -40vmin 0 5.414531594070969vmin currentColor;
  }
  .background span:nth-child(14) {
    color: #E45A84;
    top: 94%;
    left: 82%;
    animation-duration: 34s;
    animation-delay: -11s;
    transform-origin: -24vw 13vh;
    box-shadow: -40vmin 0 5.122828706992731vmin currentColor;
  }
  .background span:nth-child(15) {
    color: #E45A84;
    top: 59%;
    left: 48%;
    animation-duration: 48s;
    animation-delay: -35s;
    transform-origin: 1vw -13vh;
    box-shadow: -40vmin 0 5.032063090214679vmin currentColor;
  }
  .background span:nth-child(16) {
    color: #583C87;
    top: 46%;
    left: 61%;
    animation-duration: 53s;
    animation-delay: -21s;
    transform-origin: 20vw -9vh;
    box-shadow: 40vmin 0 5.061864701803998vmin currentColor;
  }
  .background span:nth-child(17) {
    color: #583C87;
    top: 10%;
    left: 78%;
    animation-duration: 37s;
    animation-delay: -18s;
    transform-origin: -9vw -23vh;
    box-shadow: -40vmin 0 5.139799522617608vmin currentColor;
  }
  .background span:nth-child(18) {
    color: #583C87;
    top: 28%;
    left: 11%;
    animation-duration: 20s;
    animation-delay: -30s;
    transform-origin: 25vw 3vh;
    box-shadow: -40vmin 0 5.754888855443759vmin currentColor;
  }
  .background span:nth-child(19) {
    color: #583C87;
    top: 88%;
    left: 33%;
    animation-duration: 27s;
    animation-delay: -49s;
    transform-origin: -20vw 6vh;
    box-shadow: 40vmin 0 5.044417086683941vmin currentColor;
  }



  /* END CSS  */


  .loginFOrm  {
    color: white!important;
    width: 40%;
    margin-left: 30%;
    background: rgba( 255, 255, 255, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
  }