
:root {
  --fc-daygrid-event-dot-width: 12px;
}
.fc .fc-scroller-liquid-absolute {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
.fc .fc-scrollgrid-liquid {
  background: floralwhite;
}
.demo-app a{
  color: #3a3a3a!important;
}
/* .fc-header-toolbar{
  display: none!important;
} */

.fc .fc-button-primary {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}

 .demo-app {
    display: flex;
    /* min-height: 80vh; */
    /* font-family: Arial, Helvetica Neue, Helvetica, sans-serif; */
    font-size: 14px;
  }
  
  .demo-app-main {
    flex-grow: 1;
    /* padding: 3em; */
  }
  
  .fc { /* the calendar root */
    max-width: 1100px;
    margin: 0 auto;
  }
  