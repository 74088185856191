@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);

:root {
  --fc-daygrid-event-dot-width: 12px;
}
.fc .fc-scroller-liquid-absolute {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
.fc .fc-scrollgrid-liquid {
  background: floralwhite;
}
.demo-app a{
  color: #3a3a3a!important;
}
/* .fc-header-toolbar{
  display: none!important;
} */

.fc .fc-button-primary {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}

 .demo-app {
    display: flex;
    /* min-height: 80vh; */
    /* font-family: Arial, Helvetica Neue, Helvetica, sans-serif; */
    font-size: 14px;
  }
  
  .demo-app-main {
    flex-grow: 1;
    /* padding: 3em; */
  }
  
  .fc { /* the calendar root */
    max-width: 1100px;
    margin: 0 auto;
  }
  
/* night mode */
:root {
  --background: #1c3553;
  --backgroundHeader: #112a45;
  --backgroundInput: rgba(255, 255, 255, 0);
  --text: #c5cdd3;
  --borderColor: #8e8e8e61;
}
/* day mode */
:root {
  --primary: #002140;
  --secondery: #1990FF;
  --third_color: white;
  --forth_color: #8e8e8e61;
  --success: #00b300;
  --danger: #FF4D4F;
  --warning: #ffc000;
  --info: #00b3ff;
  --text: black;
  --borderColor: #dadada;
  --background: #F0F2F5;
  --backgroundHeader: white;
  --backgroundInput:white;
}


/* Main frame */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.main-frame{
  padding: 24px;
  min-height: "100vh";
}
.window-frame{
  min-height: 100vh!important;
}
.main-frame-content{
  margin: 0px 16px!important;
}

.site-layout .site-layout-background {
  background: white;
  background: var(--third_color);
}
.site-layout-background .main-frame{
  min-height: 100vh!important;
}

.ant-layout-header {

  padding: 0 15px!important;
}
/* Sidebar */

.logo {
  margin-top: 0px;
  margin-left: 16px ;
  background-color: "transparent";
  z-index: "-20",;
  
}
.sidebar{
  background: #002140;
  background: var(--primary);
}



/* Froms */

.section-login-from{
 background-color: #002140;
 height: 100vh;
}

.section-login-from h1{
  padding-top:20%;
  color: #ffffff;

}
.section-login-from p{
  padding: 0px 50px;
  color: #ffffff;
}


.ant-tree-title{
  display: inline-block;
  background-color: aliceblue;
  padding: 3px 30px 3px 30px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 6px;
  box-shadow: 1px 1px #e5e5e5;
  outline: none;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  /* background-color: #eaecefeb; */
  border-radius: 10px;
} 
.table_import{
  width: 100%;
  
  border: 1px solid lightgray;
}
.table_heading{
  background-color: lightgray;
  text-align: center;
}
.table_import >tr > th{
  padding: 10px;
}
.table_import >tr > td{
  text-align: center;
  padding: 7px;
  border-bottom: 1px solid lightgray;
}
.ql-editor {
  min-height: 100px;
}
.Stock_Warehouse.active{
  background-color: lightgreen;
  color: white!important;
}
.Stock_Variation.active{
  background-color: lightgreen;
  color: white!important;
}

/* dark mode */

.main-frame{
  -webkit-backdrop-filter: blur(5px)!important;
          backdrop-filter: blur(5px)!important;
}
main{
  color: black;
  color: var(--text);
  background: #F0F2F5;
  background: var(--background);
}
.site-layout{
  background: #F0F2F5;
  background: var(--background);
}
h1, h2, h3, h4, h5, h6 {
  color: black;
  color: var(--text);
}
.ant-table {
  background: white;
  background: var(--backgroundHeader);
}
.ant-table-thead > tr > th {
  color:black;
  color:var(--text);
  /* background: var(--backgroundHeader); */
  border-bottom: 1px solid #dadada;
  border-bottom: 1px solid var(--borderColor);
  transition: background 0.3s ease;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #dadada;
  border-bottom: 1px solid var(--borderColor);
  /* color: var(--text); */
  transition: background 0.3s;
}
.ant-drawer-content {
  background-color: white;
  background-color: var(--backgroundHeader);
  color: black;
  color: var(--text)
}
.ant-drawer-header {
  color: black;
  color: var(--text);
  background: #F0F2F5;
  background: var(--background);
}
.ant-drawer-title {
  color:black;
  color:var(--text);
}
.ant-form-item-label > label {
  color: black;
  color: var(--text);
}
.ant-drawer-body p.site-description-item-profile-p {
  color:black;
  color:var(--text);
}
.ant-col{
  color: black;
  color: var(--text);
}
.ant-breadcrumb > span:last-child {
  color:black;
  color:var(--text);
}
.ant-breadcrumb {
  color: black;
  color: var(--text);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: white;
  background-color: var(--backgroundInput);
  border: 1px solid #d9d9d9;
}
.ant-input-number {
  background-color: white;
  background-color: var(--backgroundInput);
  width: 100%!important;
  color: black;
  color: var(--text)
}
.ant-select-selection-item{
  background-color: white;
  background-color: var(--backgroundInput);
  color: black;
  color: var(--text)
}
.ant-input-number-input-wrap{
  background-color: white;
  background-color: var(--backgroundInput);
  color: black;
  color: var(--text)
}
.ant-picker{
  background-color: white;
  background-color: var(--backgroundInput);
  color: black;
  color: var(--text)
}
.ant-picker-input{
  background-color: white;
  background-color: var(--backgroundInput);
  color: black;
  color: var(--text)
}



/* orginal CSS */
.Item-list-header{
  border: 1px solid lightgray;
  padding: 5px 10px;
  margin-bottom: 1px;
}
.Item-list{
  border: 1px solid lightgray;
  padding: 10px 10px;
  margin-bottom: 1px;
  background: whitesmoke;
  margin: auto!important;
}
.history_table{
  width: 100%;
}
.history_table td{
  border:  1px solid lightgray;
  padding: 5px 5px;
  /* height: 50px; */
}
.displayblock{
  display: block!important ;
}


/* invoice css starts */

.costing_table{
  width: 100%;
  border: 1px solid lightgray;
}
.costing_table th{
  border: 1px solid lightgray;
  color: lightgray;
  font-weight: 300;
}
.costing_table td{
  text-align: center;
  padding: 5px;
}

.account_table{
  width: 100%;
}
.account_table td{
  border:  1px solid lightgray;
  padding: 5px 5px;
  /* height: 50px; */
}
.product_table{
  width: 100%;
  text-align: center;
  /* font-size: 10px; */
  border: 1px solid black;
}

.product_table td{
  border:  1px solid lightgray;
  /* padding: 5px 2px; */
  /* height: 50px; */
  height: 20px;
}

.vat_table{
  width: 100%;
  text-align: center;
  border: 1px solid black;
}

.vat_table td{
  border:  1px solid lightgray;
  height: 30px;
}

.product_table_quick{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}

.product_table_quick td{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
}
.invoice_print_fontSize_quick{
  font-size: 14px;
}

.invoice_print_fontSize{
  font-size: 10px;
  /* font-size: 24px; */
}
.wordrobe_print_fontSize{
  font-size: 12px;
  /* font-size: 24px; */
}

.order_span{
  font-size: 10px;
  display: block;
  border: 1px solid lightgray;
  width: 80%;
  padding: 3px 1px;
  margin: auto;
}
.order_span_bill{
  /* font-size: 10px; */
  display: block;
  /* border: 1px solid black; */
  width: 100%;
  padding: 3px 5px;
  margin: auto;
  margin-bottom: 1px;
}
.order_span_service{
  /* font-size: 10px; */
  display: block;
  border: 1px solid black;
  width: 80%;
  padding: 5px 5px;
}


.sponsorship_order_span{
  display:block;
  border: 1px solid lightgray;
  width: 100%;
  padding: 3px 5px;
  margin-top: -5px;
  margin-bottom: 5px;
}

/* invoice css ends */

.attendance_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}

.attendance_table td{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
}
.salary_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}

.salary_table td{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
}

.loan_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}

.loan_table td{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
}

.leave_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}

.leave_table td{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
}
.payslip_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}
.payslip_table th{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
  width: 50%;
}
.payslip_table td{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
  width: 25%;
}
.payslip_table input.ant-input-number-input {
  text-align: center;
}
.voucher_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}
.voucher_table td{
  border-top:  1px solid lightgray;
  border-bottom:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
}
.voucher_table_title{
  line-height: 40px;
}
.voucher_table_title td{
  margin-bottom: 10px;
}


.td_dotted{
  width: 20%;
  border-bottom: 1px dotted gray;
}
.td_dotted1{
  width: 30%;
  border-bottom: 1px dotted gray;
}

.summary_card{
  border-radius: 5px;
  color: black;
  font-weight: 600;
  font-size: 16px;
}


.table_purple{
  background-color: #7c238c;
  color: white;
}
.table_dark_Purple{
  background-color: #42033d;
  color: white;
}
.table_orange{
  background-color: orange;
  color: white;
}
.table_light_green{
  background-color: #80ff72;
  color: black;
}
.table_green{
  background-color: #F95738;
  color: #000000;
}
.table_yellow{
  background-color: #fff07c;
  color: black;
}
.table_light_yellow{
  background-color: #F0FFC2;
  color: black;
}
.table_gray{
  background-color: #8491a3;
  color: black;
}
.table_sky{
  background-color: #7ee8fa;
  color: black;
}
.table_red{
  background-color: rgb(252, 139, 139);
  color: black;
}
.table_light_red{
  background-color: #FFE4C0;
  color: black;
}
.rtable a{
  color: black;
}
.table_purple a{
  color: #F5F5F5;
}
.table_dark_Purple a{
  color: #F5F5F5;
}
.table_Cancelled{
  background-color: #100007;
  color: white;
}
.table_Cancelled a{
  color: white;
}
.rtable:hover a{
  color: black;
}
.rtable:hover{
  color: black;
}

.voucher_inputnumber > .ant-input-number-input-wrap{
  min-width: 400px;
}
.d-div > p {
  margin-bottom: 0px;
}

.stock{
  font-size: 14px!important;
  font-weight: 500;
 
}

.transactions >  .ant-card-body{
  padding: 10px;
}
.ant_right {
  text-align: right;
}

.ant-drawer-content img{
  max-height: 60px;
}
  /* end of id card */
  .ant-drawer-content {

border: 1px solid rgba( 255, 255, 255, 0.18 );
color: black;
  }


/* Attendance button */

.entryButton {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  background: #FF4D4F;
  background: var(--danger);
  padding: 15px;
  margin: 0px;
  text-align: center;
  color: white;
  border-radius: 10px;
}

.exitButton {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  background: #ffc000;
  background: var(--warning);
  padding: 15px;
  margin: 0px;
  text-align: center;
  color: black;
  border-radius: 10px;
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  line-height: 1.5715;
}
.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0,0,0,.85);
}





/* body{
    text-align: center;
} */

/* CSS background */

@-webkit-keyframes move {
    100% {
        -webkit-transform: translate3d(0, 0, 1px) rotate(360deg);
                transform: translate3d(0, 0, 1px) rotate(360deg);
    }
  }

@keyframes move {
    100% {
        -webkit-transform: translate3d(0, 0, 1px) rotate(360deg);
                transform: translate3d(0, 0, 1px) rotate(360deg);
    }
  }
  
  .background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #3E1E68;
    overflow: hidden;
  }
  
  .background span {
    width: 20vmin;
    height: 20vmin;
    border-radius: 20vmin;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    -webkit-animation: move;
            animation: move;
    -webkit-animation-duration: 45;
            animation-duration: 45;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  
  
  .background span:nth-child(0) {
    color: #E45A84;
    top: 50%;
    left: 28%;
    -webkit-animation-duration: 41s;
            animation-duration: 41s;
    -webkit-animation-delay: -12s;
            animation-delay: -12s;
    -webkit-transform-origin: 25vw 3vh;
            transform-origin: 25vw 3vh;
    box-shadow: 40vmin 0 5.5509582725325926vmin currentColor;
  }
  .background span:nth-child(1) {
    color: #FFACAC;
    top: 69%;
    left: 14%;
    -webkit-animation-duration: 31s;
            animation-duration: 31s;
    -webkit-animation-delay: -48s;
            animation-delay: -48s;
    -webkit-transform-origin: -23vw -11vh;
            transform-origin: -23vw -11vh;
    box-shadow: -40vmin 0 5.961656893456132vmin currentColor;
  }
  .background span:nth-child(2) {
    color: #E45A84;
    top: 65%;
    left: 41%;
    -webkit-animation-duration: 55s;
            animation-duration: 55s;
    -webkit-animation-delay: -40s;
            animation-delay: -40s;
    -webkit-transform-origin: 13vw 0vh;
            transform-origin: 13vw 0vh;
    box-shadow: -40vmin 0 5.510652454444377vmin currentColor;
  }
  .background span:nth-child(3) {
    color: #583C87;
    top: 59%;
    left: 14%;
    -webkit-animation-duration: 43s;
            animation-duration: 43s;
    -webkit-animation-delay: -14s;
            animation-delay: -14s;
    -webkit-transform-origin: 3vw 22vh;
            transform-origin: 3vw 22vh;
    box-shadow: 40vmin 0 5.906923956588535vmin currentColor;
  }
  .background span:nth-child(4) {
    color: #583C87;
    top: 54%;
    left: 24%;
    -webkit-animation-duration: 36s;
            animation-duration: 36s;
    -webkit-animation-delay: -13s;
            animation-delay: -13s;
    -webkit-transform-origin: -1vw -8vh;
            transform-origin: -1vw -8vh;
    box-shadow: 40vmin 0 5.7959442021458525vmin currentColor;
  }
  .background span:nth-child(5) {
    color: #FFACAC;
    top: 21%;
    left: 63%;
    -webkit-animation-duration: 44s;
            animation-duration: 44s;
    -webkit-animation-delay: -44s;
            animation-delay: -44s;
    -webkit-transform-origin: 1vw 23vh;
            transform-origin: 1vw 23vh;
    box-shadow: -40vmin 0 5.061171945724339vmin currentColor;
  }
  .background span:nth-child(6) {
    color: #583C87;
    top: 12%;
    left: 87%;
    -webkit-animation-duration: 39s;
            animation-duration: 39s;
    -webkit-animation-delay: -11s;
            animation-delay: -11s;
    -webkit-transform-origin: -16vw -11vh;
            transform-origin: -16vw -11vh;
    box-shadow: -40vmin 0 5.65163276073673vmin currentColor;
  }
  .background span:nth-child(7) {
    color: #FFACAC;
    top: 86%;
    left: 77%;
    -webkit-animation-duration: 34s;
            animation-duration: 34s;
    -webkit-animation-delay: -36s;
            animation-delay: -36s;
    -webkit-transform-origin: 15vw -19vh;
            transform-origin: 15vw -19vh;
    box-shadow: -40vmin 0 5.6425229861137804vmin currentColor;
  }
  .background span:nth-child(8) {
    color: #FFACAC;
    top: 15%;
    left: 75%;
    -webkit-animation-duration: 43s;
            animation-duration: 43s;
    -webkit-animation-delay: -46s;
            animation-delay: -46s;
    -webkit-transform-origin: 8vw -4vh;
            transform-origin: 8vw -4vh;
    box-shadow: 40vmin 0 5.611049099833652vmin currentColor;
  }
  .background span:nth-child(9) {
    color: #583C87;
    top: 77%;
    left: 57%;
    -webkit-animation-duration: 41s;
            animation-duration: 41s;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    -webkit-transform-origin: -23vw 23vh;
            transform-origin: -23vw 23vh;
    box-shadow: -40vmin 0 5.8430929337943205vmin currentColor;
  }
  .background span:nth-child(10) {
    color: #FFACAC;
    top: 59%;
    left: 83%;
    -webkit-animation-duration: 10s;
            animation-duration: 10s;
    -webkit-animation-delay: -14s;
            animation-delay: -14s;
    -webkit-transform-origin: -20vw -22vh;
            transform-origin: -20vw -22vh;
    box-shadow: 40vmin 0 5.630212037556413vmin currentColor;
  }
  .background span:nth-child(11) {
    color: #FFACAC;
    top: 96%;
    left: 73%;
    -webkit-animation-duration: 54s;
            animation-duration: 54s;
    -webkit-animation-delay: -10s;
            animation-delay: -10s;
    -webkit-transform-origin: 9vw 25vh;
            transform-origin: 9vw 25vh;
    box-shadow: 40vmin 0 5.116428887668621vmin currentColor;
  }
  .background span:nth-child(12) {
    color: #E45A84;
    top: 17%;
    left: 30%;
    -webkit-animation-duration: 45s;
            animation-duration: 45s;
    -webkit-animation-delay: -16s;
            animation-delay: -16s;
    -webkit-transform-origin: 16vw 2vh;
            transform-origin: 16vw 2vh;
    box-shadow: -40vmin 0 5.5110773761671386vmin currentColor;
  }
  .background span:nth-child(13) {
    color: #FFACAC;
    top: 17%;
    left: 67%;
    -webkit-animation-duration: 32s;
            animation-duration: 32s;
    -webkit-animation-delay: -41s;
            animation-delay: -41s;
    -webkit-transform-origin: 23vw 4vh;
            transform-origin: 23vw 4vh;
    box-shadow: -40vmin 0 5.414531594070969vmin currentColor;
  }
  .background span:nth-child(14) {
    color: #E45A84;
    top: 94%;
    left: 82%;
    -webkit-animation-duration: 34s;
            animation-duration: 34s;
    -webkit-animation-delay: -11s;
            animation-delay: -11s;
    -webkit-transform-origin: -24vw 13vh;
            transform-origin: -24vw 13vh;
    box-shadow: -40vmin 0 5.122828706992731vmin currentColor;
  }
  .background span:nth-child(15) {
    color: #E45A84;
    top: 59%;
    left: 48%;
    -webkit-animation-duration: 48s;
            animation-duration: 48s;
    -webkit-animation-delay: -35s;
            animation-delay: -35s;
    -webkit-transform-origin: 1vw -13vh;
            transform-origin: 1vw -13vh;
    box-shadow: -40vmin 0 5.032063090214679vmin currentColor;
  }
  .background span:nth-child(16) {
    color: #583C87;
    top: 46%;
    left: 61%;
    -webkit-animation-duration: 53s;
            animation-duration: 53s;
    -webkit-animation-delay: -21s;
            animation-delay: -21s;
    -webkit-transform-origin: 20vw -9vh;
            transform-origin: 20vw -9vh;
    box-shadow: 40vmin 0 5.061864701803998vmin currentColor;
  }
  .background span:nth-child(17) {
    color: #583C87;
    top: 10%;
    left: 78%;
    -webkit-animation-duration: 37s;
            animation-duration: 37s;
    -webkit-animation-delay: -18s;
            animation-delay: -18s;
    -webkit-transform-origin: -9vw -23vh;
            transform-origin: -9vw -23vh;
    box-shadow: -40vmin 0 5.139799522617608vmin currentColor;
  }
  .background span:nth-child(18) {
    color: #583C87;
    top: 28%;
    left: 11%;
    -webkit-animation-duration: 20s;
            animation-duration: 20s;
    -webkit-animation-delay: -30s;
            animation-delay: -30s;
    -webkit-transform-origin: 25vw 3vh;
            transform-origin: 25vw 3vh;
    box-shadow: -40vmin 0 5.754888855443759vmin currentColor;
  }
  .background span:nth-child(19) {
    color: #583C87;
    top: 88%;
    left: 33%;
    -webkit-animation-duration: 27s;
            animation-duration: 27s;
    -webkit-animation-delay: -49s;
            animation-delay: -49s;
    -webkit-transform-origin: -20vw 6vh;
            transform-origin: -20vw 6vh;
    box-shadow: 40vmin 0 5.044417086683941vmin currentColor;
  }



  /* END CSS  */


  .loginFOrm  {
    color: white!important;
    width: 40%;
    margin-left: 30%;
    background: rgba( 255, 255, 255, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
/* for id card */
:root{
    --white: #ffffff;
}
*{
    margin: 0;
    padding: 0;
}
.main_div {
    display: grid;
    grid-template-columns: 380px 380px;
    grid-template-rows: 640px;
    font-family: 'Open Sans', sans-serif;
    grid-gap: 10px;
}
.font_page {
	background: #ffffff;
	background: var(--white);
	text-align: center;
	box-shadow: 0 0 10px #bbbbbb;
	border-radius: 15px;
    
}
.back_page {
    background-color: #925A22;
    color: #ffffff;
    color: var(--white);
    text-align: center;
    box-shadow: 0 0 10px #bbbbbb;
    border-radius: 10px;
}
.bg_color {
    background: #ffffff;
    background: var(--white);
    position: relative;
    z-index: 1;
    padding: 15px 0 0 0;
    background-color: #925A22;
    border-radius: 10px;
  }
  .bg_color::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    background-color: #ffffff;
    background-color: var(--white);
    /* clip-path: polygon(100% 0, 100% 35%, 100% 100%, 83% 86%, 38% 81%, 20% 87%, 1% 100%, 0% 35%, 0 0); */
    content: "";
    z-index: -1;
    border-top-left-radius: 100%;
}
.bg_color::after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    background-color: #ffffff;
    background-color: var(--white);
    /* clip-path: polygon(100% 0, 100% 35%, 100% 100%, 83% 86%, 38% 81%, 20% 87%, 1% 100%, 0% 35%, 0 0); */
    content: "";
    z-index: -1;
    border-top-right-radius: 100%;
}
.bg_color img {
	width: 180px;
	border-radius: 50%;
	height: 180px;
	object-fit: cover;
	display: block;
	margin: 0 auto;
	border: 2px solid;
	padding: 8px;
}


.bg_color h3 {
    color: #ffffff;
    color: var(--white);
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 20px;
    font-weight: 500;
  }
  .profile_info h3 {
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .job_title {
	font-size: 24px;
	font-weight: 300;
	line-height: 30px;
	margin-bottom: 20px;
}
  .phone_number {
    font-size: 18px;
    line-height: 36px;
  }
  .phone_number i {
    font-size: 22px;
    width: 35px;
    height: 35px;
    border: 1px solid #000000;
    line-height: 35px;
  }
  .date_info {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-content: center;
    align-items: flex-end;
  }
  .id_card_logo img {
	max-width: 280px;
	display: block;
	margin: 0 auto;
    margin-bottom: 25px;
}
.font_page_discription{
    padding: 0 20px;
}
.profile_info {
	padding: 40px 0;
}
/* Back Post */
.shap {
	height: 300px;
	background: #ffffff;
	background: var(--white);
	border-radius: 10px 10px 38% 38%;
	justify-content: center;
	align-items: center;
	display: flex;
}


.address_info {
    display: flex;
    padding: 40px 0;
    position: relative;
  }

  .address_info::before {
	position: absolute;
	left: 50%;
	top: 0;
	width: 2px;
	height: 90%;
	background: #ffffff;
	content: "";
}
  .address_info .address:first-child {
    text-align: right;
    width: 50%;
    padding-right: 15px;
  }
  .address_info .address:last-child {
    text-align: left;
    width: 50%;
    padding-left: 15px;
  }
  .address span {
    display: block;
    font-size: 29px;
    font-weight: 500;
  }
  .footer_text p i {
    font-size: 22px;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    border: 1px solid #fff;
    line-height: 35px;
    border-radius: 50%;
    text-align: center;
  }
  .simple_text {
    color: #925A22;
    font-size: 53px;
    line-height: 39px;
    letter-spacing: 2px;
  }
  .simple_text small {
    text-align: right;
    display: block;
    opacity: 0.5;
  }
  .footer_text {
    text-align: left;
    padding-left: 12%;
  }
  .footer_text p {
    margin-bottom: 10px;
  }
  .notifi_bar{
    margin: "20px 0px";
    border-bottom: 1px solid #F0F2F5;
  }

  .glossy_card{
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 1px 6px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 5px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
  .glossy_card > .ant-col {
    padding-left: 1px!important;
    padding-right: 1px!important;
  }


.summary_card > .ant-card-body > h3{
  font-size: 14px!important;
  font-weight: 700;
  border-bottom: 2px solid rgb(202, 202, 202);
  display: inline-block;
  padding-bottom: 2px;
  font-family: sans-serif;
}

:root {
  --fc-daygrid-event-dot-width: 12px;
}
.fc .fc-scroller-liquid-absolute {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
a{
  color: #3a3a3a;
}

 .demo-app {
    display: flex;
    /* min-height: 80vh; */
    /* font-family: Arial, Helvetica Neue, Helvetica, sans-serif; */
    font-size: 14px;
  }
  
  .demo-app-main {
    flex-grow: 1;
    /* padding: 3em; */
  }
  
  .fc { /* the calendar root */
    max-width: 1100px;
    margin: 0 auto;
  }
  

:root {
  --fc-daygrid-event-dot-width: 12px;
}
.fc .fc-scroller-liquid-absolute {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
a{
  color: #3a3a3a;
}

 .demo-app {
    display: flex;
    /* min-height: 80vh; */
    /* font-family: Arial, Helvetica Neue, Helvetica, sans-serif; */
    font-size: 14px;
  }
  
  .demo-app-main {
    flex-grow: 1;
    /* padding: 3em; */
  }
  
  .fc { /* the calendar root */
    max-width: 1100px;
    margin: 0 auto;
  }
  
